<template>
	<div class="home">
		<v-img
			alt="owo"
			class="owo-img"
			src="@/assets/owo-cry.png"
			transition="scale-transition"
			contain
			height="200"
			width="200"
		/>
		<span class="text-h4">404. What are you doing here?</span>
	</div>
</template>

<script>
export default {
	name: 'HomePage',
};
</script>

<style scoped>
.home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
}

.owo-img {
	max-height: 200px;
}
</style>
